// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.

@import 'node_modules/swiper/swiper-bundle.min.css';

.md .toast-bottom {
  top: -3.5rem;
}

.ios .toast-bottom {
  top: -3.125rem;
}

.md .toast-top {
  top: -3.5rem;
}

.ios .toast-top {
  top: -2.75rem;
}

.ad6-loader .loading-wrapper {
  background-color: var(--ion-background-color);

  .loading-content ion-label {
    padding: 4px 0;
    font-weight: bold;
    color: var(--ion-color-primary);
  }
}

.content-item {
  table {
    img {
      max-width: 3rem;
      max-height: 3rem;
    }
  }

  &--weekschema {
    table {
      margin-bottom: 1rem;
      width: 100%;

      tr {
        &:nth-child(odd) {
          background-color: var(--ion-color-step-150);
        }

        &:nth-child(even) {
          background-color: var(--ion-color-step-100);
        }

        td:nth-child(3) {
          word-break: break-word;
        }

        td {
          padding: 0 0.125rem;
          vertical-align: top;
        }
      }

      &>thead,
      &>tbody {
        tr:nth-child(1) td {
          padding-top: 1rem;
          padding-bottom: 1rem;
          background-color: var(--ion-color-primary);
          color: var(--ion-color-primary-contrast);
        }
      }

      thead+tbody tr:nth-child(1) td {
        padding-top: 0;
        padding-bottom: 0;
        background-color: inherit;
        color: inherit;
      }
    }
  }
}
