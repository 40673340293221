// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open_Sans/OpenSans-VariableFont_wdth-wght.ttf');
}

@font-face {
  font-family: 'Ubuntu Regular';
  src: url('./assets/fonts/Ubuntu/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: 'Ubuntu Light';
  src: url('./assets/fonts/Ubuntu/Ubuntu-Light.ttf');
}

@font-face {
  font-family: 'Ubuntu Bold';
  src: url('./assets/fonts/Ubuntu/Ubuntu-Bold.ttf');
}

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
  --ion-font-family: 'Open Sans';
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .ad6-bold {
  font-family: var(--ion-font-heading-bold);
}

.flusso-logo {
  width: 100%;
  text-align: center;

  img {
    width: 16rem;
  }
}
ion-accordion:not(:has(div[slot="content"])) ion-icon:last-child {
  display: none;
}
ion-modal.ad6-content-search {
  --width: 80%;
  --height: 80%;
}
