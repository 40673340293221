// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-normal: "Open Sans";
  --ion-font-heading-regular: "Ubuntu Regular";
  --ion-font-heading-light: "Ubuntu Light";
  --ion-font-heading-bold: "Ubuntu Bold";

  --ion-color-primary: #1e3f5c;
  --ion-color-primary-rgb: 30, 63, 92;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1a3751;
  --ion-color-primary-tint: #35526c;

  --ion-color-secondary: #1876a8;
  --ion-color-secondary-rgb: 24, 118, 168;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #156894;
  --ion-color-secondary-tint: #2f84b1;

  --ion-color-tertiary: #71cbf4;
  --ion-color-tertiary-rgb: 113, 203, 244;
  --ion-color-tertiary-contrast: var(--ion-color-primary);
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #63b3d7;
  --ion-color-tertiary-tint: #7fd0f5;

  --ion-color-success: #acdef9;
  --ion-color-success-rgb: 172, 222, 249;
  --ion-color-success-contrast: var(--ion-color-primary);
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #97c3db;
  --ion-color-success-tint: #b4e1fa;

  --ion-color-warning: #f6a320;
  --ion-color-warning-rgb: 246, 163, 32;
  --ion-color-warning-contrast: var(--ion-color-primary);
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d88f1c;
  --ion-color-warning-tint: #f7ac36;

  --ion-color-danger: #e52725;
  --ion-color-danger-rgb: 229, 39, 37;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ca2221;
  --ion-color-danger-tint: #e83d3b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: var(--ion-color-primary);
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f2f3f6;
  --ion-color-light-rgb: 242, 243, 246;
  --ion-color-light-contrast: var(--ion-color-primary);
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d5d6d8;
  --ion-color-light-tint: #f3f4f7;

  --swiper-navigation-color: var(--ion-color-light);
  --swiper-pagination-color: var(--ion-color-light);

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-color-step-50: #f4f4f4;
  --ion-color-step-100: #e9e9ea;
  --ion-color-step-150: #dededf;
  --ion-color-step-200: #d3d3d4;
  --ion-color-step-250: #c8c8c9;
  --ion-color-step-300: #bdbdbf;
  --ion-color-step-350: #b2b2b4;
  --ion-color-step-400: #a7a7a9;
  --ion-color-step-450: #9c9c9e;
  --ion-color-step-500: #919294;
  --ion-color-step-550: #858789;
  --ion-color-step-600: #7a7c7e;
  --ion-color-step-650: #6f7173;
  --ion-color-step-700: #646669;
  --ion-color-step-750: #595b5e;
  --ion-color-step-800: #4e5053;
  --ion-color-step-850: #434548;
  --ion-color-step-900: #383a3d;
  --ion-color-step-950: #2d2f33;

  --ion-color-ad6-blue1: #1e3f5c;
  --ion-color-ad6-blue1-rgb: 30, 63, 92;
  --ion-color-ad6-blue1-contrast: #ffffff;
  --ion-color-ad6-blue1-contrast-rgb: 255, 255, 255;
  --ion-color-ad6-blue1-shade: #1a3751;
  --ion-color-ad6-blue1-tint: #35526c;

  --ion-color-ad6-blue2: #1f5b82;
  --ion-color-ad6-blue2-rgb: 31, 91, 130;
  --ion-color-ad6-blue2-contrast: #ffffff;
  --ion-color-ad6-blue2-contrast-rgb: 255, 255, 255;
  --ion-color-ad6-blue2-shade: #1b5072;
  --ion-color-ad6-blue2-tint: #356b8f;

  --ion-color-ad6-blue3: #1876a8;
  --ion-color-ad6-blue3-rgb: 24, 118, 168;
  --ion-color-ad6-blue3-contrast: #ffffff;
  --ion-color-ad6-blue3-contrast-rgb: 255, 255, 255;
  --ion-color-ad6-blue3-shade: #156894;
  --ion-color-ad6-blue3-tint: #2f84b1;

  --ion-color-ad6-blue4: #35abe2;
  --ion-color-ad6-blue4-rgb: 53, 171, 226;
  --ion-color-ad6-blue4-contrast: var(--ion-color-primary);
  --ion-color-ad6-blue4-contrast-rgb: 0, 0, 0;
  --ion-color-ad6-blue4-shade: #2f96c7;
  --ion-color-ad6-blue4-tint: #49b3e5;

  --ion-color-ad6-blue5: #71cbf4;
  --ion-color-ad6-blue5-rgb: 113, 203, 244;
  --ion-color-ad6-blue5-contrast: var(--ion-color-primary);
  --ion-color-ad6-blue5-contrast-rgb: 0, 0, 0;
  --ion-color-ad6-blue5-shade: #63b3d7;
  --ion-color-ad6-blue5-tint: #7fd0f5;

  --ion-color-ad6-blue6: #acdef9;
  --ion-color-ad6-blue6-rgb: 172, 222, 249;
  --ion-color-ad6-blue6-contrast: var(--ion-color-primary);
  --ion-color-ad6-blue6-contrast-rgb: 0, 0, 0;
  --ion-color-ad6-blue6-shade: #97c3db;
  --ion-color-ad6-blue6-tint: #b4e1fa;

  --ion-color-ad6-orange: #f6a320;
  --ion-color-ad6-orange-rgb: 246, 163, 32;
  --ion-color-ad6-orange-contrast: var(--ion-color-primary);
  --ion-color-ad6-orange-contrast-rgb: 0, 0, 0;
  --ion-color-ad6-orange-shade: #d88f1c;
  --ion-color-ad6-orange-tint: #f7ac36;

  --ion-color-ad6-red: #e52725;
  --ion-color-ad6-red-rgb: 229, 39, 37;
  --ion-color-ad6-red-contrast: #ffffff;
  --ion-color-ad6-red-contrast-rgb: 255, 255, 255;
  --ion-color-ad6-red-shade: #ca2221;
  --ion-color-ad6-red-tint: #e83d3b;

  --ion-color-ad6-step-50: #294964;
  --ion-color-ad6-step-100: #35526c;
  --ion-color-ad6-step-150: #405c74;
  --ion-color-ad6-step-200: #4b657d;
  --ion-color-ad6-step-250: #566f85;
  --ion-color-ad6-step-300: #62798d;
  --ion-color-ad6-step-350: #6d8295;
  --ion-color-ad6-step-400: #788c9d;
  --ion-color-ad6-step-450: #8395a5;
  --ion-color-ad6-step-500: #8f9fae;
  --ion-color-ad6-step-550: #9aa9b6;
  --ion-color-ad6-step-600: #a5b2be;
  --ion-color-ad6-step-650: #b0bcc6;
  --ion-color-ad6-step-700: #bcc5ce;
  --ion-color-ad6-step-750: #c7cfd6;
  --ion-color-ad6-step-800: #d2d9de;
  --ion-color-ad6-step-850: #dde2e7;
  --ion-color-ad6-step-900: #e9ecef;
  --ion-color-ad6-step-950: #f4f5f7;

  --ion-text-color: var(--ion-color-primary);
  --ion-text-color-rgb: var(--ion-color-primary-rgb);

  --ion-tab-bar-background: var(--ion-color-light);
  --ion-tab-bar-background-selected: pink;
  --ion-tab-bar-border-color: var(--ion-color-dark);
  --ion-tab-bar-color: var(--ion-color-medium-shade);
  --ion-tab-bar-color-selected: var(--ion-color-ad6-blue3);
}

.ion-color-ad6-blue1 {
  --ion-color-base: var(--ion-color-ad6-blue1);
  --ion-color-base-rgb: var(--ion-color-ad6-blue1-rgb);
  --ion-color-contrast: var(--ion-color-ad6-blue1-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ad6-blue1-contrast-rgb);
  --ion-color-shade: var(--ion-color-ad6-blue1-shade);
  --ion-color-tint: var(--ion-color-ad6-blue1-tint);
}

.ion-color-ad6-blue2 {
  --ion-color-base: var(--ion-color-ad6-blue2);
  --ion-color-base-rgb: var(--ion-color-ad6-blue2-rgb);
  --ion-color-contrast: var(--ion-color-ad6-blue2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ad6-blue2-contrast-rgb);
  --ion-color-shade: var(--ion-color-ad6-blue2-shade);
  --ion-color-tint: var(--ion-color-ad6-blue2-tint);
}

.ion-color-ad6-blue3 {
  --ion-color-base: var(--ion-color-ad6-blue3);
  --ion-color-base-rgb: var(--ion-color-ad6-blue3-rgb);
  --ion-color-contrast: var(--ion-color-ad6-blue3-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ad6-blue3-contrast-rgb);
  --ion-color-shade: var(--ion-color-ad6-blue3-shade);
  --ion-color-tint: var(--ion-color-ad6-blue3-tint);
}

.ion-color-ad6-blue4 {
  --ion-color-base: var(--ion-color-ad6-blue4);
  --ion-color-base-rgb: var(--ion-color-ad6-blue4-rgb);
  --ion-color-contrast: var(--ion-color-ad6-blue4-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ad6-blue4-contrast-rgb);
  --ion-color-shade: var(--ion-color-ad6-blue4-shade);
  --ion-color-tint: var(--ion-color-ad6-blue4-tint);
}

.ion-color-ad6-blue5 {
  --ion-color-base: var(--ion-color-ad6-blue5);
  --ion-color-base-rgb: var(--ion-color-ad6-blue5-rgb);
  --ion-color-contrast: var(--ion-color-ad6-blue5-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ad6-blue5-contrast-rgb);
  --ion-color-shade: var(--ion-color-ad6-blue5-shade);
  --ion-color-tint: var(--ion-color-ad6-blue5-tint);
}

.ion-color-ad6-blue6 {
  --ion-color-base: var(--ion-color-ad6-blue6);
  --ion-color-base-rgb: var(--ion-color-ad6-blue6-rgb);
  --ion-color-contrast: var(--ion-color-ad6-blue6-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ad6-blue6-contrast-rgb);
  --ion-color-shade: var(--ion-color-ad6-blue6-shade);
  --ion-color-tint: var(--ion-color-ad6-blue6-tint);
}

.ion-color-ad6-orange {
  --ion-color-base: var(--ion-color-ad6-orange);
  --ion-color-base-rgb: var(--ion-color-ad6-orange-rgb);
  --ion-color-contrast: var(--ion-color-ad6-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ad6-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-ad6-orange-shade);
  --ion-color-tint: var(--ion-color-ad6-orange-tint);
}

.ion-color-ad6-red {
  --ion-color-base: var(--ion-color-ad6-red);
  --ion-color-base-rgb: var(--ion-color-ad6-red-rgb);
  --ion-color-contrast: var(--ion-color-ad6-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ad6-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-ad6-red-shade);
  --ion-color-tint: var(--ion-color-ad6-red-tint);
}
